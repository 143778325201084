






import { Component, Vue } from 'vue-property-decorator';
import AlarmListCard from './AlarmListCard.vue';

@Component({
  components: {
    AlarmListCard,
  },
})
export default class AlarmList extends Vue {}
